
import { Flex, AbsoluteCenter, Box, useMediaQuery } from '@chakra-ui/react'
import ProjectCardDescription from './ProjectCardDescription.js'

export default function ProjectCard( {children, title, stack, image, left, link})
{
	const [isLargerThan480] = useMediaQuery("(min-width: 690px)");

	return (
		<div>
			<Flex w="100%" mx="auto" h={300}
              	bg="#1a202c">
     			 {isLargerThan480 && left && (
     			 <Box
     			   flex={1}
     			   textAlign="right"
     			   color="#ccd6f6"
     			   bg={"#1a202c"}
     			   backgroundImage={image}
     			   backgroundSize="cover"
     			   backgroundPosition="center"
     			   position="relative"
     			 />
     			 )}
     			 <Box
     			   flex={1}
     			   color="#ccd6f6"
     			   bg={isLargerThan480 ? "#1a202c" : "none"}
     			   backgroundImage={!isLargerThan480 && image}
     			   backgroundSize={!isLargerThan480 && "cover"}
     			   backgroundPosition={!isLargerThan480 && "center"}
     			   position={!isLargerThan480 && "relative"}
     			 >
        {isLargerThan480 ? (
        	<>

				<ProjectCardDescription link={link} title={title} description={children} stack={stack} />
  			</>
        ) : (
          <>
            <Box
              position="absolute"
              top={0}
              bottom={0}
              left={0}
              right={0}
              bg="#1a202c"
              opacity={0.8}
            />
			 <Box>
				<AbsoluteCenter w="100%">

					<ProjectCardDescription 
						link={link} 
						title={title} 
						description={children} 
						stack={stack} 
					/>
				</AbsoluteCenter>
			</Box>
          </>
        )}
      </Box>
     			 {isLargerThan480 && !left && (
     			 <Box
     			   flex={1}
     			   textAlign="right"
     			   color="#ccd6f6"
     			   bg={"#1a202c"}
     			   backgroundImage={image}
     			   backgroundSize="cover"
     			   backgroundPosition="center"
     			   position="relative"
     			 />
     			 )}
    </Flex>

			
	</div>
	)
}
