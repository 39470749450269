import { Flex, Text, Link } from '@chakra-ui/react'


export default function ProjectCardDescription({ link, title, description, stack})
{

	const userAgent = navigator.userAgent.toLowerCase();
	const handleClick = async () => {
		const requestOptions = {
			method: 'POST',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({"id": link, "device": userAgent})
		}

		const url = 'https://hook.eu1.make.com/o5mjed75q6cmsh6iplniiobi8jcorkd1'

		await fetch(url, requestOptions)
	}

	return (
		<Flex
				  height="100%"
				  flexDirection="column"
				  alignItems="left"
				  justifyContent="center"
				  	ml="10%"
				  	mr="10%"
				>
  				<Text 
  					fontSize='xl' 
  					fontWeight='600' 
  					textAlign="left"
  					color='custom.50' >
					{link && (
  						<Link href={link} isExternal onClick={handleClick}>{title}</Link>)}
  					{!link && (<>{title}</>)}
  				</Text>

  				<Text 
  					fontSize='lg' 
  					fontWeight='400' 
  					color='custom.100' 
  					textAlign="left"
  					style={{ lineHeight: '120%'}}
  				>
  				{description} 
  				</Text>
  				<Text 
  					as='span'
  					fontSize='sm' 
  					textAlign="left"
  					style={{ fontFamily: 'Ubuntu Mono, sans-serif'}}
  				><br />{stack}
  					{link && (
  						<>&nbsp;<br /><Link href={link} isExternal onClick={handleClick}>See on github
  						</Link>
  						</>
  						)}
  				</Text>
  			</Flex>
  	);

}
