import { extendTheme } from '@chakra-ui/react'
//https://stackoverflow.com/questions/65886321/cant-change-default-fonts-in-chakra-ui
const config = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
  colors: {
	  custom: {
		50: '#ccd6f6',
		100: '#8892b0',
		200: '#8892b0',
		300: '#64ffda',
		400: '#112240',
	}, 
	  custom2: {
		50: '#ccd6f6',
		100: '#8892b0',
		200: '#8892b0',
		300: '#64ffda',
		400: '#64ffda',
		500: '#64ffda',
		600: '#64ffda',
		700: '#64ffda',
		800: '#64ffda',
		900: '#64ffda',
	}, 
  },
  fonts: {
    heading: `'Calibre', sans-serif`,
    body: `'Calibre', sans-serif`,
  },
  styles: {
    global: {
      // styles for the `body`
      body: {
        bg: '#0a192f',
        color: 'custom.200',
        fontSize: 'xl',
      },
      container: {
        backgroundColor: '#0a192f',
      },
    }
  }
}

const theme = extendTheme( config )

export default theme

