import { useState, useRef} from 'react'
import { Flex, Center, Divider, Avatar, Box, Container, Text, useMediaQuery } from '@chakra-ui/react'
import Contact from './Contact.js'
import Projects from './Projects.js'
import Button from './Button.js'

function Resume({ language })
{

	const [isLargerThan370] = useMediaQuery("(min-width: 370px)");
	const [shortText, setShortText] = useState(true);
	const refTexto = useRef(null);
	const [isLargerThan480] = useMediaQuery("(min-width: 690px)");


	const handleShortText = () => {
		setShortText(false);
	};
	const handleScroll = () => {

		refTexto.current.scrollIntoView({ behavior: "smooth" });
		setShortText(true);

	};

	if (language === 'en')
	return (
		<div>

		<Container maxWidth='100ch' mb={1} h={[300, 250, 200, 150]}>
			<Box mt="15%" >
				 <Flex alignItems="center">
			{isLargerThan480 && (
      <Avatar
        size="xl"
        src="avatar.png"
        borderRadius="full"
        mr={1}
          mt={-7}
      />)}
      				 <Box>
				<Text as='h1' fontSize='6xl' fontWeight='600' style={{ lineHeight: '100%'}} color='custom.50'  >Alberto García</Text>
				<Text as='h2' fontSize='6xl' fontWeight='600'color='custom.100' style={{ lineHeight: '100%'}}>C, C++ and Javascript developer;</Text>
					 </Box>
    </Flex>
				
			</Box>
			
			<a href='CV Alberto García López.pdf' download>
			<Button id="1">
				<Text as='span' color='custom.300' mt={5}>
   				 Download resume 🇬🇧
   				</Text>
    		</Button></a>
			<a href='CV Alberto García_es.pdf' download>
			<Button id="2">
				<Text as='span' color='custom.300' mt={5}>
   				 Descargar CV 🇪🇸
   				</Text>
    		</Button></a>
		</Container>
		{isLargerThan370 && (<Container h="100" />)} 
		{!isLargerThan370 && (<Container h="120" />)}


		<Container maxWidth='100ch'>
			<Text fontWeight='600' as='span' color='custom.300' ref={refTexto} >
				About me
			</Text>
		<Divider mb={5}/>
<Box
      position="relative"
      display="inline-block"
      fontWeight="400"
      color="custom.200"
    >
    {shortText && (
      <Box
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height="100%"
        bgGradient="linear(to bottom, transparent, #0a192f)"
      />
    )}
		<Text >Since I was born, there has always been a computer at home, that's why <Text as='span' color='custom.300'>I've spent my
			life playing around with technology. </Text>When the Internet reached my home, I spent my free
time snooping around and learning, investigating how websites worked,<Text as='span' color='custom.300'>  studying HTML and
				creating simple sites in Geocities, MSN Communities, installing PhpBB forums, Wordpress,
	etc. </Text>Do you remember .tk domains?<br /><br />

			Years later, in 2016, <Text as='span' color='custom.300'>I founded a startup where I learnt from many areas</Text>, such as defining
			the <Text as='span' color='custom.300'>first MVPs</Text>, facing the market's reality and rapidly iterating, the
product definition and development processes and how to manage a team, all of this in an
environment of growth and uncertainty. <Text as='span' color='custom.300'>That's where I took development seriously and
	started to see technology from a business perspective.</Text><br /><br />

			{!shortText && (
					<>
I've took advantage of the Internet's MOOCs, and thanks to sites like edx, Coursera,

Freecodecamp and Fullstackopen, I've been able to <Text as='span' color='custom.300'>learn languages such as Java, Javascript, and C, work with relational and non-relational databases, etc.</Text><br /><br />

					However, in <Text as='span' color='custom.300'>42 Madrid</Text> is where I've learnt the most, a programming school with campuses in
more than 20 countries, learning through a project-based methodology where you have to
sort it out by yourself, with p2p evaluations. That's where <Text as='span' color='custom.300'>I've learnt the fundaments of software
				development with low level languages such as C and C++.</Text><br /><br />

Being a student at 42 has encouraged me to keep growing professionaly as a programmer and
I'm very fortunate for being part of this community, because <Text as='span' color='custom.300'>it's absolutely different
				from the bootcamps, universities or any other traditional forms of education</Text>, and moreover
it's free, as it is funded by Fundación Telefónica. In fact, I'd highly recommend to all
people interested in programming, whether or not they know how to program, to give it a
			try, but I warn you: the selection process lasts 26 days and they are tough.<br /><br />
					</>
			)}
			</Text>
    </Box>
			{shortText && (
					<Center>
					<Box onClick={handleShortText}>
					<Button id="3">Read more</Button>
					</Box>
</Center>
			)}
			{!shortText && (
			<Center>
			<Box onClick={handleScroll}>
			<Button id="4">Read less</Button>
			</Box>
			</Center>
			)}

		</Container>

		<Projects />
		<Container h={50}>

		</Container>
		<Container maxWidth={"100ch"}>
			<Text fontWeight='600' as='span' color='custom.300'>
			Contact me	
			</Text>
			
		<Divider mb={5}/>
			<Contact />
		</Container>
		</div>

	)
	else
		return (<Container></Container>);
};


export default Resume;
