import ProjectCard from "./ProjectCard.js"
import { Divider, Container, Text, Box} from '@chakra-ui/react'


export default function Projects()
{

	return (<div> 
		
		<Container maxWidth='100ch'> 
			<Text fontWeight='400' color='custom.100'>
				<Text fontWeight='600' as='span' color='custom.300'>Projects </Text>
			</Text>
		<Divider mb={5}/>
		<ProjectCard 
			title="Transcendence" 
			image="projects/transcendence.gif"
			stack="Nest JS&nbsp; &nbsp;Angular &nbsp;&nbsp;  PostgreSQL &nbsp;&nbsp; Docker"
			link="https://github.com/yeta1990/ft_transcendence"
			left={false}

		>
			Web app to play Pong online against other players: <br />
			- Challenges, ranking, ladder, different game modes and power ups.<br />
			- Chat rooms private messages, friends, etc.<br />
			- Websockets, Oauth2, 2FA.<br />

		</ProjectCard>
		<Box h={20} />
		<ProjectCard 
			title="Proof of concept - Haveibeenpwned API" 
			description="Experiments with different Haveibeenpwned endpoints:
			- Signup page with exposed password checking, "
			image="projects/poc.gif"
			stack="Next.js &nbsp; &nbsp;Chakra UI &nbsp;&nbsp;  Chart.js  &nbsp;&nbsp; Docker"
			link="https://github.com/yeta1990/poc-haveibeenpwned"
			left={true}

		>
			Experiments with some Haveibeenpwned endpoints:<br />
			- Signup page with exposed password checking<br />
			- Data charts processed from different sources<br />
			- Exposes 3 new endpoints with the processed data<br />

		</ProjectCard>
		<Box h={20} />
		<ProjectCard 
			title="Containers" 
			image="projects/containers.gif"
			stack="C++"
			link="https://github.com/yeta1990/ft_containers"
			left={false}

		>
			Reimplementation of some data structures from C++ 98 STL.<br/>
			- Vector, Map, Stack and Set<br />
			- Including iterators, reverse iterators and a red-black tree.<br/>
			- Stress tests with similar performance compared to STL.<br/>

		</ProjectCard>
		<Box h={20} />
		<ProjectCard 
			title="IRC server" 
			image="projects/irc.gif"
			stack="C++"
			left={true}
			link="https://github.com/yeta1990/irc_server"

		>
    		IRC Server based on RFC1459 protocol.<br />
    		- Compatible with IRC clients who follow the same protocol (i.e.: irssi, Textual, etc.)<br/>
    		- Includes most of the channel operations and channel modes.<br/>

		</ProjectCard>
		<Box h={20} />
		<ProjectCard 
			title="Minishell" 
			image="projects/minishell.gif"
			stack="C"
			link="https://github.com/yeta1990/minishell"
			left={false}

		>

    		Unix-like shell: a command interpreter inspired in bash.<br />
    - Allows command execution, redirections and pipes.<br />
    - Implements its own builtins: env, export, cd, echo and unset.<br />

		</ProjectCard>
		<Box h={20} />
		<ProjectCard 
			title="Docker ubuntu with GCC and Clang" 
			image="projects/docker.gif"
			stack="Docker &nbsp; DockerHub"
			link="https://github.com/yeta1990/docker-gcc-clang"
			left={true}

		>
			Portable environment to ease the search for bugs and leaks in C and C++.<br/>
			- Packed with GCC, Clang, Valgrind and vim.<br />  
    		- Image build and upload to DockerHub is automatic when a PR is merged.<br />
    		- Tested on Mac, Linux and Windows.<br /> 

		</ProjectCard>

		</Container>

	</div>)
}
