import { HStack, Avatar, Box, Link, Image } from '@chakra-ui/react'

export default function Contact() {

	const userAgent = navigator.userAgent.toLowerCase();
	const handleClick = async (link) => {
		const requestOptions = {
			method: 'POST',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({"id": link, "device": userAgent})
		}

		const url = 'https://hook.eu1.make.com/o5mjed75q6cmsh6iplniiobi8jcorkd1'

		await fetch(url, requestOptions)
	}

	return (
<Box
    >

<HStack w="100%" >
	<Box  w={"45px"} mr={"55px"}>
      <Avatar
        size="xl"
        src="avatar.png"
        borderRadius="full"
          mt={-1}
      />
    </Box>

	<Box w={"100%"}>
<HStack mt={3}>


  <Box height='32px' w="32px">

    <Link href="https://www.linkedin.com/in/alberto-garc%C3%ADa/" isExternal onClick={() => handleClick("linkedin")}>
    	<Image boxSize="25px" src="https://cdn.simpleicons.org/linkedin/white" />
    </Link>

  </Box>
  <Box height='30px' w="100%" mt={3}>
    <Link href="https://www.linkedin.com/in/alberto-garc%C3%ADa/" isExternal onClick={() => handleClick("linkedin")}>
    	linkedin.com/in/alberto-garcía
    </Link>
  </Box>

</HStack>

<HStack mt={1}>
  <Box height='32px' w="32px">

     <Link href="https://github.com/yeta1990" isExternal onClick={() => handleClick("github")}>
    	<Image boxSize="25px" src="https://cdn.simpleicons.org/github/white" />
    </Link>

  </Box>
  <Box height='30px' mt={3}>
      <Link href="https://github.com/yeta1990" isExternal onClick={()=> handleClick("github")}>
    	github.com/yeta1990
    </Link>
  </Box>

</HStack>

<HStack mt={1}>
  <Box height='32px' w="32px">

    	<Image boxSize="25px" src="https://cdn.simpleicons.org/maildotru/white" />

  </Box>
  <Box height='30px' mt={3}>
    	albertogarcia90 at gmail
  </Box>
</HStack>
  </Box>
</HStack>

</Box>
	);
}
