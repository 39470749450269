import { Text } from '@chakra-ui/react'
import './Button.css'

export default function Button( { id, children } )
{

	const userAgent = navigator.userAgent.toLowerCase();
	const handleClick = async () => {
		const requestOptions = {
			method: 'POST',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({"id": id, "device": userAgent})
		}

		const url = 'https://hook.eu1.make.com/o5mjed75q6cmsh6iplniiobi8jcorkd1'

		await fetch(url, requestOptions)
	}


	return(
		<button className="buttonstyle" onClick={handleClick}>

			<Text as='span' color='custom.300' mt={5}>
				{children}
   			</Text>
		</button>
	)
}
