import { Box, ChakraProvider } from '@chakra-ui/react'
import theme from "./styles/theme.js"
import Resume from "./components/Resume.js"
import React from 'react';



		//<Button colorScheme='blue' onClick={()=>toggleLang()}>Button</Button>

function App() {
	/*
	const [lang, setLang] = useState('en');

	const toggleLang = () => {
		if (lang === 'en')
			setLang('es');
		else
			setLang('en');
	}
	*/

  return (
	<ChakraProvider resetCSS theme={theme}>
		<Box>
			<Resume language='en' />
		</Box>
		<Box h={20} />
    </ChakraProvider>
  );
}

export default App;
